var render = function render(){var _vm=this,_c=_vm._self._c;return _c('timeline-entry-item',{staticClass:"system-note",class:{
    target: _vm.isTargetNote,
    'pr-0': _vm.shouldShowDescriptionVersion,
    [`system-note-variant-${_vm.variant}`]: _vm.variant,
  },attrs:{"id":_vm.noteAnchorId}},[_c('div',{staticClass:"gl-relative gl-float-left gl-flex gl-items-center gl-justify-center gl-rounded-full",class:[
      _vm.iconBgClass,
      {
        'system-note-icon -gl-mt-1 gl-ml-2 gl-h-6 gl-w-6': _vm.isAllowedIcon,
        'system-note-dot -gl-top-1 gl-ml-4 gl-mt-3 gl-h-3 gl-w-3 gl-border-2 gl-border-solid gl-border-gray-50 gl-bg-gray-900':
          !_vm.isAllowedIcon,
      },
    ]},[(_vm.isAllowedIcon)?_c('gl-icon',{attrs:{"name":_vm.systemNoteIconName,"size":14,"data-testid":"timeline-icon"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"gl-ml-7"},[_c('div',{staticClass:"gl-flex gl-items-start gl-justify-between"},[_c('note-header',{attrs:{"author":_vm.note.author,"created-at":_vm.note.created_at,"note-id":_vm.note.id,"is-system-note":true,"is-imported":_vm.note.imported},scopedSlots:_vm._u([(_vm.canSeeDescriptionVersion || _vm.note.outdated_line_change_path)?{key:"extra-controls",fn:function(){return [(_vm.canSeeDescriptionVersion)?_c('gl-button',{staticClass:"gl-ml-3 gl-align-text-bottom !gl-text-sm",attrs:{"variant":"link","icon":_vm.descriptionVersionToggleIcon,"data-testid":"compare-btn"},on:{"click":_vm.toggleDescriptionVersion}},[_vm._v(_vm._s(_vm.__('Compare with previous version')))]):_vm._e(),_vm._v(" "),(_vm.note.outdated_line_change_path)?_c('gl-button',{staticClass:"gl-align-text-bottom !gl-text-sm",attrs:{"icon":_vm.showLines ? 'chevron-up' : 'chevron-down',"variant":"link","data-testid":"outdated-lines-change-btn"},on:{"click":_vm.toggleDiff}},[_vm._v("\n            "+_vm._s(_vm.__('Compare changes'))+"\n          ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('span',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.actionTextHtml),expression:"actionTextHtml"}],ref:"gfm-content"})])],1),_vm._v(" "),_c('div',{staticClass:"note-body gl-pb-3 gl-pl-3"},[_c('div',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.note.note_html),expression:"note.note_html"}],staticClass:"note-text md",class:{
          'gl-block gl-overflow-hidden': _vm.hasMoreCommits,
          'system-note-commit-list': _vm.hasMoreCommits && !_vm.expanded,
        }}),_vm._v(" "),(_vm.hasMoreCommits)?_c('div',{staticClass:"flex-list"},[_c('div',{staticClass:"flex-row gl-relative gl-z-2 gl-cursor-pointer gl-pl-4 gl-pt-3 gl-text-blue-500 hover:gl-underline",on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('gl-icon',{staticClass:"gl-mr-2",attrs:{"name":_vm.toggleIcon,"size":12}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.__('Toggle commit list')))])],1)]):_vm._e(),_vm._v(" "),(_vm.shouldShowDescriptionVersion)?_c('div',{staticClass:"gl-relative !gl-pt-3",attrs:{"data-testid":"description-version"}},[(_vm.isLoadingDescriptionVersion)?_c('pre',{staticClass:"loading-state"},[_vm._v("          "),_c('gl-skeleton-loader'),_vm._v("\n        ")],1):_c('pre',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.descriptionVersion),expression:"descriptionVersion"}],staticClass:"gl-mt-3 gl-whitespace-pre-wrap gl-pr-7"}),_vm._v(" "),(_vm.displayDeleteButton)?_c('gl-button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],staticClass:"gl-absolute",class:_vm.deleteButtonClasses,attrs:{"title":_vm.$options.i18n.deleteButtonLabel,"aria-label":_vm.$options.i18n.deleteButtonLabel,"variant":"default","category":"tertiary","icon":"remove","data-testid":"delete-description-version-button"},on:{"click":_vm.deleteDescriptionVersion}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.lines.length && _vm.showLines)?_c('div',{staticClass:"gl-my-2 gl-mr-5 gl-overflow-hidden gl-overflow-visible gl-rounded-small gl-border-1 gl-border-solid gl-border-gray-200 gl-pl-0"},[_c('table',{staticClass:"code js-syntax-highlight",class:_vm.$options.userColorSchemeClass,attrs:{"data-testid":"outdated-lines"}},_vm._m(0),0)]):(_vm.showLines)?_c('div',{staticClass:"gl-mt-4"},[_c('gl-skeleton-loader')],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _vm._l((_vm.lines),function(line){return _c('tr',{key:line.line_code,staticClass:"line_holder"},[_c('td',{staticClass:"diff-line-num old_line !gl-rounded-none !gl-border-0 !gl-border-b-0 !gl-border-t-0",class:line.type},[_vm._v("\n              "+_vm._s(line.old_line)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"diff-line-num new_line !gl-border-b-0 !gl-border-t-0",class:line.type},[_vm._v("\n              "+_vm._s(line.new_line)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"line_content !gl-table-cell !gl-rounded-none !gl-border-0",class:line.type,domProps:{"innerHTML":_vm._s(line.rich_text /* eslint-disable-line vue/no-v-html */)}})])})
}]

export { render, staticRenderFns }